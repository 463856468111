.main-home{
    /* background: url('../images/home-banner.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* min-height: 100vh; */
}

.main-home .btn-main{
    --tw-shadow-color: #8EA9CC;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.main-home .btn-main-alt, nav .btn-main-alt{
    --tw-shadow-color: #8EA9CC;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.main-home .btn-main-alt:hover{
    background: #CDEAF4;
}

#tsparticles canvas{
  /* max-height: 200px !important; */
}

section{
    position: relative;
    z-index: 50;
}




.fullpage-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

/* Apply active style to the currently visible section */
.section.active {
  z-index: 1;
}

/* Navigation dots */
.nav-dots {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.nav-dot.active {
  background-color: #333;
}